import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv'; // For CSV export
import jsPDF from 'jspdf'; // For PDF export
import autoTable from 'jspdf-autotable';

const DataTableComponent = ({ businessId, token }) => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Fetch invoices with pagination and search
    const fetchInvoices = async (page = 1, search = '', rowsPerPage = 10) => {
        setLoading(true); // Start loading

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-invoices/${businessId}?page=${page}&search=${search}&limit=${rowsPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setInvoices(data.invoices);
            setTotalPages(data.totalPages);
            setCurrentPage(data.page);
        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false); // End loading
        }
    };


    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchInvoices(page, searchTerm, rowsPerPage); // Fetch new page data
    };

    // Handle search term change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        fetchInvoices(1, e.target.value, rowsPerPage); // Fetch data with the new search term
    };
    const handleRowsPerPageChange = (newRowsPerPage, page) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(page);
        fetchInvoices(page, searchTerm, newRowsPerPage); // Pass new limit
    };

    const wrapText = (text, maxWordsPerLine) => {
        const words = text.split(' ');
        let lines = [];
        for (let i = 0; i < words.length; i += maxWordsPerLine) {
            lines.push(words.slice(i, i + maxWordsPerLine).join(' '));
        }
        return lines.join('<br/>');
    };

    // Fetch invoices on initial render and when page or search changes
    useEffect(() => {
        fetchInvoices(currentPage, searchTerm, rowsPerPage);
    }, []);

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
            sortable: true,
        },
        {
            name: 'Invoice No',
            selector: (row) => (
                <a target='blank' rel="noopener noreferrer" style={{ color: '#81a683', fontWeight: 'bold', textDecoration: 'underline' }} href={`/${row.unique_code}`}>
                    {row.invoice_number}
                </a>
            ),
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: (row) => row.customer_name,
            sortable: true,
        },
        {
            name: 'Date',
            selector: (row) => row.invoice_date,
            sortable: true,
        },
        {
            name: 'Cell',
            selector: (row) => row.customer_cell,
            sortable: true,
        },
        {
            name: 'Discount',
            selector: (row) => row.discount,
            sortable: true,
        },
        {
            name: 'Paid Amount',
            selector: (row) => `${row.paid_amount} (${row.payment_mode})`,
            sortable: true,
        },
        {
            name: 'Rating',
            selector: (row) => {
                const stars = Array.from({ length: 5 }, (_, index) => index < row.rating);
                const wrappedReview = row.review ? wrapText(row.review, 20) : '';  // Wrap review text

                return (
                    <span className="styled-tooltip">
                        {row.rating !== null && // Check if the rating is not null
                            stars.map((filled, index) => (
                                <span key={index} className={`star ${filled ? 'filled' : ''}`} >
                                    ★
                                </span>
                            ))}
                        {row.rating !== null && row.review && (
                            <span className="tooltiptext" dangerouslySetInnerHTML={{ __html: wrappedReview }}></span>
                        )}
                    </span>
                );
            },
            sortable: true,
        },
    ];

    return (
        <div className="content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '30px' }}>
                <div>
                    <CSVLink
                        data={invoices.map(invoice => ({
                            'S.No': invoices.indexOf(invoice) + 1,
                            'Invoice No': invoice.invoice_number,
                            'Customer Name': invoice.customer_name,
                            'Date': invoice.invoice_date,
                            'Cell': invoice.customer_cell,
                            'Discount': invoice.discount,
                            'Paid Amount': `${invoice.paid_amount} (${invoice.payment_mode})`,
                            'Rating': invoice.rating,
                        }))}
                        filename={"invoices.csv"}
                        className="btn btn-primary print-hidden"
                        style={{ margin: '2px' }}
                    >
                        CSV
                    </CSVLink>
                    <button className="btn btn-primary print-hidden" onClick={() => window.print()} style={{ margin: '2px' }}>
                        Print
                    </button>
                </div>
                <input
                    type="text"
                    className='form-control print-hidden'
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: '200px' }}
                />
            </div>
            <DataTable
                columns={columns}
                data={invoices}
                striped
                responsive
                noDataComponent="No invoices found."
                pagination
                paginationServer
                paginationTotalRows={totalPages * rowsPerPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={currentPage}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            />

        </div>
    );
};

export default DataTableComponent;
