import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv'; // Make sure to import CSVLink

const ReviewsTable = ({ businessId, token }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        if (!businessId || !token) {
            console.error('Missing businessId or token. Cannot fetch reviews.');
            return;
        }
        fetchReviews(1, '', rowsPerPage); // Fetch on mount with initial params
    }, [businessId, token]);

    const fetchReviews = async (page = 1, search = '', limit = 10) => {
        if (!businessId || !token) {
            console.error('Invalid businessId or token');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-all-reviews/${businessId}?page=${page}&search=${search}&limit=${limit}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error fetching reviews:', response.status, errorText);
                throw new Error('Failed to fetch reviews');
            }

            const data = await response.json();
            setReviews(data.reviews || []);
            setTotalRows(data.totalRows || 0);
            setCurrentPage(page);
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        fetchReviews(page, searchTerm, rowsPerPage);
    };

    const handleSearchChange = (e) => {
        const search = e.target.value;
        setSearchTerm(search);
        fetchReviews(1, search, rowsPerPage); // Reset to page 1 when searching
    };

    const handleRowsPerPageChange = (newLimit) => {
        setRowsPerPage(newLimit);
        fetchReviews(1, searchTerm, newLimit); // Reset to page 1 with new limit
    };

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
            sortable: false,
            width: '10%',
        },
        {
            name: 'Customer Name',
            selector: (row) => < div className="review-text" > {row.customer_name}</div > || 'N/A',
            sortable: true,
        },
        {
            name: 'Customer Cell',
            selector: (row) => <div className="review-text">{row.customer_cell}</div> || 'N/A',
            sortable: true,
        },
        {
            name: 'Rating',
            selector: (row) => {
                const rating = Math.min(Math.max(row.rating, 0), 5); // Ensure rating is between 0 and 5
                const stars = Array.from({ length: 5 }, (_, index) => index < rating);
                return (
                    <span>
                        {rating !== null &&
                            stars.map((filled, index) => (
                                <span key={index} className={`star ${filled ? 'filled' : ''}`}>
                                    ★
                                </span>
                            ))}
                    </span>
                );
            },
            sortable: true,
        },
        {
            name: 'Review',
            selector: (row) => row.review || 'No Review',
            sortable: false,
            cell: (row) => <div className="review-text">{row.review}</div>,
        },
    ];

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CSVLink
                        data={reviews.map((review, index) => ({
                            'S.No': index + 1,
                            'Customer Name': review.customer_name,
                            'Customer Cell': review.customer_cell,
                            'Rating': review.rating,
                            'Review': review.review,
                        }))}
                        filename={"reviews.csv"}
                        className="btn btn-primary print-hidden"
                        style={{ margin: '2px' }}
                    >
                        CSV
                    </CSVLink>
                    <button className="btn btn-primary print-hidden" onClick={() => window.print()} style={{ margin: '2px' }}>
                        Print
                    </button>
                </div>
                <input
                    type="text"
                    className='form-control print-hidden'
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: '200px' }}
                />
            </div>

            <DataTable
                columns={columns}
                data={reviews}
                progressPending={loading}
                striped
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            />
        </div>
    );
};

export default ReviewsTable;
